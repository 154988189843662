<template>
  <div
    class="d-flex align-items-center justify-content-center"
    style="margin-top: 5%">
    <BlockUI :blocked="showLoading">
      <Card>
        <template #content>
          <div>
            Para validação, verificar a correspondência dos dados abaixo com as
            informações do documento apresentado. <br />
            <br />
            Caso os dados informados
            <span class="font-bold">não</span> coincidam com os seus, a ficha
            financeira em questão não é válida. <br />
            <br />Entre em contato com seu setor de
            <span class="font-bold">Recursos Humanos (RH)</span>.
          </div>
        </template>
      </Card>
      <Card>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-5">
              <label for="nome">Nome</label>
              <InputText
                id="nome"
                v-model="fichaValida.nome"
                class="input-text card-desktop"
                disabled />
              <Textarea
                v-model="fichaValida.nome"
                class="card-mobile input-text"
                rows="3"
                cols="30"
                disabled />
            </div>
            <div class="field col-6 md:col-3">
              <label for="matricula">Matrícula</label>
              <InputText
                id="matricula"
                v-model="fichaValida.matricula"
                class="input-text card-desktop"
                disabled />
            </div>
            <div class="field col-6 md:col-4">
              <label for="orgao">Órgão</label>
              <InputText
                id="orgao"
                v-model="fichaValida.orgao"
                class="input-text card-desktop"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-3">
              <label for="cpf">CPF</label>
              <InputText
                id="cpf"
                v-model="fichaValida.cpf"
                class="input-text card-desktop"
                disabled />
            </div>
            <div class="field col-12 md:col-5">
              <label for="cargo">Cargo</label>
              <InputText
                id="cargo"
                v-model="fichaValida.cargo"
                class="input-text card-desktop"
                disabled />
            </div>
            <div class="field col-6 md:col-2">
              <label for="lotacao">Lotação</label>
              <InputText
                id="lotacao"
                v-model="fichaValida.lotacao"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col-2">
              <label for="regime">Regime</label>
              <InputText
                id="regime"
                v-model="fichaValida.regime"
                class="input-text"
                disabled />
            </div>
          </div>
          <div class="p-fluid formgrid grid">
            <div class="field col-6 md:col-4">
              <label for="proventos">Total Proventos</label>
              <InputText
                id="proventos"
                v-model="fichaValida.totalProventos"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col-4">
              <label for="descontos">Total Descontos</label>
              <InputText
                id="descontos"
                v-model="fichaValida.totalDescontos"
                class="input-text"
                disabled />
            </div>
            <div class="field col-6 md:col-4">
              <label for="liquido">Total Líquido</label>
              <InputText
                id="liquido"
                v-model="fichaValida.totalLiquido"
                class="input-text"
                disabled />
            </div>
          </div>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>
<script>
import FichaFinanceiraService from '@/service/FichaFinanceiraService'
export default {
  props: ['codigo', 'matricula', 'entidade', 'exercicio'],

  data() {
    return {
      showLoading: false,
      params: new Map(),
      fichaValida: {},
    }
  },

  created() {
    this.fichaFinanceiraService = new FichaFinanceiraService(this.$http)
    this.showLoading = true
  },

  mounted() {
    this.carregarValidacao()
  },

  methods: {
    carregarValidacao() {
      this.params.set('codigo', this.codigo)
      this.params.set('matricula', this.matricula)
      this.params.set('entidade', this.entidade)
      this.params.set('exercicio', this.exercicio)

      this.fichaFinanceiraService
        .validarFichaFinanceira(this.params)
        .then((res) => {
          this.fichaValida = res
          this.showLoading = false
        })
        .catch((err) => {
          this.showToastError(err)
        })
    },

    showToastError(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style scoped>
.input-text {
  width: 100%;
  color: black;
}
</style>
