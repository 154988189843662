export default class ContrachequeService {
  constructor(http) {
    this._http = http
  }

  async getListaExercicios(matriculaId) {
    const { data } = await this._http.get(
      `/api/fichaFinanceira/listarExercicios/${matriculaId}`,
    )
    return data
  }

  async getListaFolhas(matriculaId) {
    const { data } = await this._http.get(
      `/api/fichaFinanceira/listarFolha/${matriculaId}`,
    )
    return data
  }

  async gerarFichaFinanceira(matriculaId, exercicio) {
    const { data } = await this._http.get(`api/fichaFinanceira/indiretas`, {
      params: {
        matriculaId: matriculaId,
        exercicio: exercicio,
        requestUrl: window.location.href.replace('ficha-financeira', ''),
      },

      responseType: 'blob',
    })

    return data
  }

  async validarFichaFinanceira(map) {
    const { data } = await this._http.get(
      `/api/fichaFinanceira/validar?codigo=${map.get(
        'codigo',
      )}&matricula=${map.get('matricula')}&entidade=${map.get(
        'entidade',
      )}&exercicio=${map.get('exercicio')}`,
    )

    return data
  }
}
